.heightauto {
  height: 'auto';
}

.h100pc {
  height: 100%;
}

.mtm75 {
  margin-top: -75px;
}

.videoContainer {
  padding-bottom: 56.25%;
}

.w150 {
  width: 150px;
}

html {
  scroll-behavior: smooth;
}
.button {
  background-color: #ff7346;
  color: #fff;
  border-radius: 8px;
}

.button:hover {
  background-color: #ff521b;
}

.nav-link.active {
  color: #4f4cd1;
}
